import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css";
import { Link } from "react-router-dom";

import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import ShowImageDialog from "../../components/app/admin/common/Dialogs/ShowImage";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";

import trivia_logo from "../../assets/img/trivia-logo.png";
import novedad_logo from "../../assets/img/novedad-logo.png";
import mision_logo from "../../assets/img/mision-logo.png";
import ranking_img from "../../assets/img/ranking.svg";
import trivia_img from "../../assets/img/quiz.svg";
import mission_img from "../../assets/img/mission.svg";

import environment from "../../libs/environment";
import MetricServices from "../../Services/Metrics/Player";
import Sparks from "../../components/Sparks";
import SummaryDialog from "../common/SummaryDialog";
import newIconStar from "../../assets/img/newIconStar.png" 

import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOnOutlined";
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Grid from "@material-ui/core/Grid";
import FlagIcon from "@material-ui/icons/Flag";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Tooltip from "@material-ui/core/Tooltip";
import DoneIcon from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

import DashboardServices from "../../Services/Dashboard";
import QuizzesServices from "../../Services/Quizzes/Global";
import NotificationsService from "../../Services/Notifications/Player";
import MissionServices from "../../Services/Mission/Player";

import { useHistory } from "react-router-dom";
import { getTime } from "../../libs/utils";

import "./styles.scss";
import "toastr/build/toastr.min.css";

import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import RankingService from "../../components/app/admin/Services/RankingService";
import sopa_de_letras_1 from "../../assets/img/sopa-de-letras-1.png";

const QuizCart = (props) => {
  const [isReaded, setIsReaded] = useState(false);
  const [showSparks, setShowSparks] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [imageModal, setImageModal] = useState(null);
  const [videoModal, setVideoModal] = useState(false);
  const [videoFromYoutube, setVideoFromYoutube] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const mission_with_images = props.data.newType == "MISSION" && props.data.otherPuclic.length > 0;

  const { t } = useTranslation();

  useEffect(() => {
    setIsReaded(props.data.isReaded);
  }, []);

  const markAsRead = (data, index) => {
    setIsReaded(true);

    NotificationsService.markAsReaded(data)
      .then(() => {
        setShowSparks(true);
      })
      .catch(() => {});
  };

  const openModalFunction = (open, image, videoModal = false, title = false) => {
    setOpenModal(open);
    setVideoModal(videoModal);
    setTitleModal(title);
    setVideoFromYoutube(false);

    if (image && image.includes("youtube")) {
      const position_one = image.indexOf("www.youtube.com/watch?v=");
      image =
        "https://www.youtube.com/embed/" + image.substr(position_one + 24);
      setVideoFromYoutube(true);
    }
    if (open) {
      setImageModal(image);
    } else {
      setImageModal(null);
    }
  };

  useEffect(() => {
    if (showSparks) {
      let timer = setTimeout(() => setShowSparks(false), 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [showSparks]);

  const history = useHistory();

  const routeChange = (link) => {
    if (link[0] === "/") {
      history.push(link);
    } else {
      if (link.includes("filesharingdev") === false) {
        window.open(link, "_blank", "noreferrer");
        return;
      }
      setOpenModal(true);
      setVideoModal(true);
      setImageModal(link);
    }
  };

  return (
    <div className="card-model card-activity">
      <Grid xs={12} sm={3} xl={3} className="card-activity-image-container">
        <div style={{ zIndex: "40" }}>
          <Sparks show={showSparks} />
        </div>
        {props.data.image ? (
          <>
            {/*<img
            src={`${environment.motivarnosBackend}${props.data.image}`}
            alt="Imagen de la trivia"
            onClick={() => openModalFunction(true, props.data.image) }
          />*/}
            <div
              className="card-activity-image-div"
              style={{
                backgroundImage: `url("${environment.motivarnosBackend}${props.data.image}")`,
              }}
              onClick={() => openModalFunction(true, props.data.image)}
            ></div>
          </>
        ) : (
          <>
            {(props.data.newType == undefined ||
              props.data.newType == "TRIVIA") && (
              <img
                src={trivia_logo}
                style={{ width: "120px" }}
                alt="Trivia"
                onClick={() => openModalFunction(true, "trivia_logo")}
              />
            )}
            {props.data.newType == "NEWS" && (
              <>
                {props.data.videoLink !== undefined &&
                props.data.videoLink !== "" ? (
                  <div
                    className="frame-image"
                    style={{ background: "url('" + novedad_logo + "')" }}
                    onClick={() =>
                      openModalFunction(true, props.data.videoLink, true)
                    }
                  >
                    <PlayCircleOutlineIcon />
                  </div>
                ) : (
                  <img
                    src={novedad_logo}
                    style={{ width: "120px" }}
                    alt="Novedad"
                    onClick={() => openModalFunction(true, "novedad_logo")}
                  />
                )}
              </>
            )}
            {props.data.newType == "SOPA" && (
              
                <img
                  src={props.data.image? `"${environment.motivarnosBackend}${props.data.image}"`: sopa_de_letras_1}
                  style={{ width: "120px" }}
                  alt="Novedad"
                  onClick={() => openModalFunction(true, "novedad_logo")}
                />
                
              )}
            {props.data.newType == "MISSION" && (
              <img
                src={mision_logo}
                style={{ width: "120px" }}
                alt="Mision"
                onClick={() => openModalFunction(true, "mision_logo")}
              />
            )}
          </>
        )}
      </Grid>
      <Grid
        xs={12}
        sm={5}
        lg={mission_with_images ? 3 : 6}
        xl={mission_with_images ? 3 : 7}
        className="card-activity-info-container"
      >
        <div className="info-container-type">
          {(props.data.newType == undefined ||
            props.data.newType == "TRIVIA") && (
            <span className="span-quiz">TRIVIAS</span>
          )}
          {props.data.newType == "SOPA" && (
            <span className="span-new">SOPA DE LETRAS</span>
          )}
          {props.data.newType == "MISSION" && (
            <span className="span-new">MISIONES</span>
          )}
          {props.data.newType == "NEWS" && (
            <span className="span-mission">NOVEDADES</span>
          )}
        </div>
        <div className="info-container-title">
          {props.data.newType == "SOPA" && (
            <>
              {props.data.title.charAt(0).toUpperCase() +
                props.data.title.slice(1)}
            </>
          )}
          {props.data.newType == "TRIVIA" && (
            <>
              {props.data.title.charAt(0).toUpperCase() +
                props.data.title.slice(1)}
            </>
          )}
          {props.data.newType == undefined && (
            <>{props.title.charAt(0).toUpperCase() + props.title.slice(1)}</>
          )}
          {props.data.newType == "MISSION" && (
            <>
              {props.data.title.charAt(0).toUpperCase() +
                props.data.title.slice(1)}
            </>
          )}
          {props.data.newType == "NEWS" && (
            <>
              {props.data.title.charAt(0).toUpperCase() +
                props.data.title.slice(1)}
            </>
          )}
        </div>
        <div className="info-container-subtitle">
          {(props.data.newType == undefined ||
            props.data.newType == "TRIVIA") &&
            props.description != null && (
              <>
                {props.description.charAt(0).toUpperCase() +
                  props.description.slice(1)}
              </>
            )}
            {(props.data.newType == "SOPA") &&
            props.data.message != null && (
              <>
                {props.data.message.charAt(0).toUpperCase() +
                  props.data.message.slice(1)}
              </>
            )}
          {(props.data.newType == "MISSION" || props.data.newType == "NEWS") &&
            props.data.message != null && (
              <>
                {props.data.message.charAt(0).toUpperCase() +
                  props.data.message.slice(1)}
              </>
            )}
        </div>
        <div className="info-container-state">
          {props.data.newType == undefined && (
            <>
              {props.secondary && (
                <>
                  <NotificationImportantIcon
                    style={{
                      fill: "rgb(158, 158, 158)",
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Pendiente{" "}
                </>
              )}
              <ErrorOutlineIcon
                style={{
                  fill: "rgb(158, 158, 158)",
                  width: "20px",
                  height: "20px",
                  marginLeft: props.secondary ? "10px" : "",
                }}
              />
              Finaliza el {format(new Date(props.data.dateTo), "dd-MM-yyyy")}
              {props.secondary == false && props.data.triviaAnswerTimeStr != null && (
              <>
                <AccessAlarmsIcon 
                  style={{
                    fill: 'rgb(158, 158, 158)', 
                    width: '20px', 
                    height: '20px', 
                    marginLeft: '10px'
                  }}
                />
                {getTime(props.data.triviaAnswerTimeStr)}
              </>
              )}
            </>
          )}
          {(props.data.newType == "MISSION" ||
            props.data.newType == "NEWS" ||
            props.data.newType == "SOPA") && (
            <>
              <ErrorOutlineIcon
                style={{
                  fill: "rgb(158, 158, 158)",
                  width: "20px",
                  height: "20px",
                }}
              />
              Finaliza el{" "}
              {format(new Date(props.data.expirationDate), "dd-MM-yyyy")}
            </>
          )}
        </div>
      </Grid>
      {mission_with_images && (
        <Grid
          xs={6}
          sm={5}
          lg={3}
          xl={4}
          className="card-activity-info-images"
        >
          {props.data.otherPuclic.map((elem, ind) => {
            if(ind >= 5) {
              return (<></>);
            }

            const fechaObjeto = new Date(props.data.expirationDate);
            const fechaActual = new Date();
            
            const title = fechaObjeto > fechaActual
              ? `Conocé las fotos más votadas de la última misión "${props.data.title}", ¡Animate a participar y votar!`
              : `Estas fueron las fotos más votadas de la última misión "${props.data.title}"`;

            return (
              <div className="card-activity-image-div-container">
                <div
                  className="card-activity-image-div"
                  style={{
                    backgroundImage: `url("${environment.motivarnosBackend}${elem.file}")`,
                  }}
                  onClick={() => openModalFunction(true, elem.file, false, title)}
                ></div>
                <div className="card-activity-winers-item-body">
                  <div className="card-activity-winers-item-body-name">
                    {elem.user.name}
                  </div>
                  <div className="card-activity-winers-item-body-icons">
                    <div className="mission-winners-item-header-button-like">
                      <div
                        className={`${
                          elem.isVoted
                            ? "mission-is-voted"
                            : "mission-is-no-voted"
                        }`}
                      >
                        <ThumbUpIcon />
                      </div>
                    </div>
                    <NavLink to={`/missions/mission?id=${props.id}`}>
                      <div>{elem.votes}</div>
                    </NavLink>
                  </div>
                </div>
              </div>
            )
          })}
        </Grid>
      )}
      <Grid
        xs={12}
        sm={5}
        lg={4}
        xl={3}
        className="card-activity-button-container"
      >
        {props.data.newType == undefined && (
          <>
            {props.secondary ? (
              <NavLink to={"/quizzes/quiz?id=" + props.id}>
                <Button className="button-activity">
                  <div className="text-inner-button-activity">JUGAR TRIVIA</div>
                  <div className="icon-inner-button-activity">
                    <SportsEsportsIcon
                      style={{ fill: "white", marginLeft: "8px" }}
                    />
                  </div>
                </Button>
              </NavLink>
            ) : (
              <SummaryDialog data={props.data} />
            )}
          </>
        )}
        {props.data.newType == "MISSION" && (
          <>
            {(props.data.link && props.data.used == undefined) ? (
              <Button
                onClick={() => routeChange(props.data.link)}
                className="button-activity"
              >
                <div className="text-inner-button-activity">HACER MISIÓN</div>
                <div className="icon-inner-button-activity">
                  <FlagIcon style={{ fill: "white", marginLeft: "8px" }} />
                </div>
              </Button>
            ) : (
              <NavLink to={"/missions/mission?id=" + props.data.mission_id}>
                <Button className="button-activity-transparent" >VER PARTICIPANTES <span className="button-activity-signo">{">"}</span></Button>
              </NavLink>
            )}
          </>
        )}
        {props.data.newType == "SOPA" && (
              <NavLink to={props.data.link}>
                <Button className="button-activity-transparent" >VER SOPA <span className="button-activity-signo">{">"}</span></Button>
              </NavLink>
        )}
        {props.data.newType == "NEWS" && (
          <>
            {props.data.link && (
              <Button
                onClick={() => routeChange(props.data.link)}
                className="button-activity"
              >
                <div className="text-inner-button-activity">VER MAS</div>
                <div className="icon-inner-button-activity">
                  <ListAltIcon style={{ fill: "white", marginLeft: "8px" }} />
                </div>
              </Button>
            )}
            {isReaded ? (
              <Tooltip title={t("readed.label", "Readed")}>
                <span className="button-activity-readed">
                  <VisibilityIcon className="pointer" />
                </span>
              </Tooltip>
            ) : (
              <>
                {props.data.newType === "NEWS" && (
                  <Tooltip title={t("mark-as-readed.label", "Mark as read")}>
                    <span
                      className="button-activity-mark-as-readed"
                      onClick={() => markAsRead(props.data)}
                    >
                      <DoneIcon className="pointer" />
                    </span>
                  </Tooltip>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {openModal && (
        <ShowImageDialog
          titleModal={titleModal}
          openModal={openModal}
          videoModal={videoModal}
          imageModal={imageModal}
          openModalFunction={(open) => openModalFunction(open)}
          videoFromYoutube={videoFromYoutube}
        />
      )}
    </div>
  );
};

QuizCart.defaultProps = {
  title: "",
  name: "",
  badge: null,
  btnText: "",
};

const UserMainRanking = (props) => {
  const [desplegado, setDesplegado] = useState(false);
  const [colaboradores, setColaboradores] = useState([]);

  const rankingsClass = {
    1: "first-position-ranking",
    2: "second-position-ranking",
    3: "third-position-ranking"
  }

  const desplegarColaboradores = () => {
    setDesplegado(!desplegado);
    console.info(props)
    RankingService.getRankingByUser(props.id)
    .then(resp=>{
      setColaboradores(resp.data);
    })
    ;
  }

  return (
    <div
      className={
        "user-ranking-wrapper " +
        (props.current ? "user-ranking-wrapper-active" : "")
      }
      tabIndex={props.current ? "-1" : ""}
    >
      <span
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <span className="name-custom-ranking">
          {props.position && (
            <div
              className={`mr-1 span-position-ranking ${rankingsClass[props.position] ? rankingsClass[props.position] : 'other-position-ranking'}`}
              ref={(el) => {
                if (el) {
                  el.style.setProperty("color", "white", "important");
                }
              }}
            >
              {props.position}
            </div>
          )}

          <Avatar
            size={"2em"}
            style={{ borderWidth: "2px" }}
            avatar={props.image}
          />
          <div class="name-div">
            <span>
              {props.name == ""
                ? props.username
                : `${props.name} ${props.lastName}`}
            </span>
          
          <span className="points-custom">
          <img src={newIconStar} alt="Custom Icon" style={{ width: '15px', height: '15px', marginRight: '4px'}} />
              {props.points != undefined ? props.points : 0}
            </span>
          </div>
          {props.qtyColaborators > 0 && (
            <span className="button-plus-ranking">
              {desplegado ? <RemoveIcon onClick={desplegarColaboradores}/> : <AddIcon onClick={desplegarColaboradores}/>}
            </span>
          )}
        </span>
      </span>
      {(colaboradores.length > 0 && desplegado) && (
        <>
          <hr className="hr-ranking"/>
          <div style={{width: '100%'}}>
            {colaboradores.map((elem, index) => {
              return (
             
                  <span className="name-custom-ranking">
                    
                  <UserMainRanking
                        collaborators={(elem.user.position != 3) ? elem.user.collaborators : []}
                        position={elem.position}
                        name={elem.user.name}
                        lastName={elem.user.lastName}
                        username={elem.user.username}
                        points={elem.myPoints}
                        positionIndex={index}
                        current={false}
                        image={`${environment.motivarnosBackend}${elem.user.image}`}
                        id={elem.user.id}
                        key={elem.user.id}
                        insigniaIcon={false}
                      />
                                        
                  </span>
            )})}
          </div>
        </>
      )}
    </div>
  );
};

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState(null);
  const [sesion, setSesion] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [badgesNotifications, setBadgesNotifications] = useState([]);
  const [ranking, setRanking] = useState(null);
  const [totalTrivias, setTotalTrivias] = useState(null);
  const [totalPointsTrivias, setTotalPointsTrivias] = useState(null);
  const [totalPointsMonthTrivias, setTotalPointsMonthTrivias] = useState(null);
  const [totalCompletedMissions, setTotalCompletedMissions] = useState(0);
  const [pointRanking, setPointRanking] = useState(null);
  const [pointTeam, setPointTeam] = useState(null);
  const [secondaryTeams, setSecondaryTeams] = useState(null);
  const [steps, setSteps] = useState([]);
  const tourRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    let sesion = JSON.parse(localStorage.getItem("sesion"));
  
    MetricServices.getMetrics()
      .then((resp) => {
        setMetrics(resp.data.content);
      })
      .catch((error) => {});
  
    DashboardServices.GetData()
      .then((response) => {
        setTotalTrivias(response.data.info.totalTrivias);
        setTotalPointsTrivias(response.data.info.point_balance);
        setTotalPointsMonthTrivias(response.data.info.pointBalanceMonth);
        if (response.data.info?.missionsInfo) {
          setTotalCompletedMissions(response.data.info.missionsInfo.achieved);
        }
        setPointRanking(response.data.ranking);
        setBadgesNotifications(response.data.insignias);
        setPointTeam(response.data.totalPuntosEquipo);
        const userRanking = response.data.ranking.find(user => user.user.id === sesion.user.id);
        if (userRanking) {
          setRanking(userRanking.position);
        } else {
          setRanking("N/A");
        }
  
        if (sesion.user.position != 3) {
          const auxilarSecondaryTeams = [];
          response.data.ranking.forEach((elem) => {
            if (elem.user.secondaryTeamName != null && !auxilarSecondaryTeams.includes(elem.user.secondaryTeamName) && elem.user.id != sesion.user.id) {
              auxilarSecondaryTeams.push(elem.user.secondaryTeamName);
            }
            if (elem.user.collaborators.length > 0) {
              elem.user.collaborators.forEach((elem2) => {
                if (elem2.secondaryTeamName != null && !auxilarSecondaryTeams.includes(elem2.secondaryTeamName) && elem2.id != sesion.user.id) {
                  auxilarSecondaryTeams.push(elem2.secondaryTeamName);
                }
              });
            }
          });
          setSecondaryTeams(auxilarSecondaryTeams);
        }
  
        MissionServices.getMissionsListPublic()
          .then((resp) => {
            let missions = resp.data.content;
            QuizzesServices.findAll()
              .then((resp) => {
                let arreglo = response.data.news.concat(resp.data.content);
                for (let i = arreglo.length - 1; i > 0; i--) {
                  let indiceAleatorio = Math.floor(Math.random() * (i + 1));
                  let temporal = arreglo[i];
                  arreglo[i] = arreglo[indiceAleatorio];
                  arreglo[indiceAleatorio] = temporal;
                }
                arreglo.forEach((data, index) => {
                  const today = new Date();
  
                  if (data.newType == 'MISSION') {
                    let mission = missions.find((elem) => elem.mission.id == data.id);
                    if (mission != undefined) {
                      const date = new Date(mission.date);
                      arreglo[index].otherPuclic =  mission.otherPuclic;
  
                      if (
                        !mission.isAvailable || 
                        (mission.otherPuclic.find((elem) => elem.user.id == sesion.user.id) != undefined) || 
                        today.getTime() > date.getTime()
                      ) {
                        arreglo[index].used = true;
                        arreglo[index].mission_id = mission.id;
                      }
                    }
                  }
                });
                setQuizzes(arreglo);
              })
              .catch(() => {});
          })
          .catch(() => {});
  
        // validar company_Id y llamar a los endpoints 
        if (sesion.user.company_Id === 5) {
          const getRankingForRole = (role) => {
            return RankingService.GetSecondaryRanking(role)
              .then((resp) => {
                const userRanking = resp.data.content.find(user => user.user.id === sesion.user.id);
                if (userRanking) {
                  setRanking(userRanking.position);
                  return true;
                }
                return false;
              })
              .catch(() => false);
          };
        
          getRankingForRole('agentes')
            .then(found => {
              if (!found) {
                return getRankingForRole('lideres');
              }
              return found;
            })
            .then(found => {
              if (!found) {
                return getRankingForRole('otrosroles');
              }
              return found;
            })
            .then(found => {
              if (!found) {
                setRanking("N/A");
              }
            })
            .catch((error) => {
              console.error("Error al obtener el ranking para cualquier rol:", error);
              setRanking("N/A");
            });
        } else {
          setRanking(response.data.info.rankingPosition || "N/A");
        }
        
      })
      .catch(() => {});
  
    /*QuizzesServices.getUser(sesion.user.id)
      .then((resp) => {
        sesion = {
          ...sesion,
          user: resp.data,
          secondaryTeam: resp.data.secondaryTeam,
        };
        setSesion(sesion);
      })
      .catch(() => {});*/
  
    setSesion(sesion);

    var textSteps = [
      'El ranking representa tu posicion actual dentro la tabla de jugadores.',
      'Esta es la cantidad de puntos que ganaste haciendo trivias, misiones y sopas de letras.', 
      'Esta es la cantidad de puntos que llevas acumulados desde el inicio del año hasta hoy.', 
      'Esta es la cantidad de puntos acumulados entre tu y tu equipo', 
      'Esta es la cantidad de trivias que realizaste durante este año.',
      'Esta es la cantidad de misiones que realizaste durante este año.'
    ];

    setSteps(textSteps.map((text, ind) => {
      const tour = new Shepherd.Tour({
        defaultStepOptions: {
          classes: "shadow-md bg-purple-dark",
          scrollTo: false,
        },
        useModalOverlay: true,
      });

      let buttonsOptions = [
        { 
          text: "Atras", 
          action: () => {
            let formerStepButton = document.getElementById("onboarding-button-"+ind);
            let nextStepButton = document.getElementById("onboarding-button-"+(ind-1));

            if (formerStepButton) {
              formerStepButton.style.display = "none";
            }

            if (nextStepButton) {
              nextStepButton.style.display = "block";
            } else {
              let nextNextStepButton = document.getElementById("onboarding-button-"+(ind-2));
              
              if (nextNextStepButton) {
                nextNextStepButton.style.display = "block";
              }
            }

            tour.complete();
          }
        },
        { 
          text: ind != (textSteps.length-1) ? "Siguiente" : "Terminar", 
          action: () => {
            let formerStepButton = document.getElementById("onboarding-button-"+ind);
            let nextStepButton = document.getElementById("onboarding-button-"+(ind+1));

            if (formerStepButton) {
              formerStepButton.style.display = "none";
            }

            if (nextStepButton) {
              nextStepButton.style.display = "block";
            } else {
              let nextNextStepButton = document.getElementById("onboarding-button-"+(ind+2));
              
              if (nextNextStepButton) {
                nextNextStepButton.style.display = "block";
              }
            }

            tour.complete();
          }
        }
      ];

      if (ind == 0) {
        buttonsOptions.splice(0, 1);  
      }
      
      tour.addStep({
        id: "onboarding-step-"+ind,
        text: text,
        attachTo: { element: ".onboarding-step-"+ind, on: "bottom" },
        buttons: buttonsOptions,
      });

      return tour
    }))
  }, []);

  const showStep = (stepIndex) => {
    tourRef.current = steps[stepIndex];

    if (tourRef.current) {
      tourRef.current.show(0)
    }
  };

  function replaceAll(string, search, replace) {
    if (string === null) return "";
    return string.split(search).join(replace).split(" ")[0];
  }

  if (!sesion) return "";

  return (
    <div className="trivia-wrapper">
      <Grid
        xs={12}
        md={sesion !== null && (sesion.company.isShowMetricDashboard || sesion.company.isShowRankingDashboard) ? 9 : 12}
      >
        <div class="card-model card-profile">
          <Grid xs={12} md={4} className="block-avatar-container">
            <div className="avatar-container">
              <div className="logo-character">
                <NavLink to="/avatar">
                  <Avatar
                    size={"120px"}
                    style={{ marginTop: "1.3em" }}
                    avatar={`${environment.motivarnosBackend}${sesion.user.image}`}
                  />
                </NavLink>
              </div>
              {badgesNotifications && badgesNotifications.length > 0 && (
                <div className="list-badges">
                  <Link to="/badges">
                    <div
                      className="badge-div"
                      style={{ backgroundColor: "#000123" }}
                    >
                      {badgesNotifications.map((badge, index) => {
                        return (
                          <img
                            key={index}
                            src={
                              badge.insignia
                                ? environment.motivarnosBackend +
                                  badge.insignia.image
                                : ""
                            }
                            alt="Badge"
                          />
                        );
                      })}
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </Grid>
          <Grid xs={12} md={8}>
            <div class="card-profile-titles">
              <p class="card-profile-title">{sesion.group.name}</p>
              {sesion.secondaryTeam != null && (
                <p class="card-profile-subtitle">{sesion.secondaryTeam.name}</p>
              )}
              {/*teams != null && (
                teams.map(t=>(<div class="equipo-secudario-name"><span>{t.secondaryTeam.name}</span></div>))
              )*/}
              <div className="card-profile-secondary-teams">
                {secondaryTeams != null && secondaryTeams.map((secondaryName) => {
                  return <span class="span-secondary-team">{secondaryName}</span>;
                })}
              </div>
            </div>
            <div class="card-profile-data">
              <div className={`card-profile-data-icons-top-pos-${pointTeam !== null ? 2 : 3}`}>
                <div className="card-profile-data-item onboarding-step-0">
                  <div class="card-profile-data-item-icon">
                    <span className="onboarding-button" id="onboarding-button-0">
                      <button className="button-shepherd" onClick={() => showStep(0)}></button>
                    </span>
                    <img src={ranking_img} />
                  </div>
                  <div class="card-profile-data-item-description">
                    <div class="card-profile-data-item-description-wrap">
                      <p class="card-profile-data-item-description-number">
                        #{ranking}
                      </p>
                      <p class="card-profile-data-item-description-title">
                        RANKING MENSUAL DE PREMIACIÓN
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-profile-data-item onboarding-step-1">
                  <div class="card-profile-data-item-icon">
                    <span className="onboarding-button onboarding-button-disabled" id="onboarding-button-1">
                      <button className="button-shepherd" onClick={() => showStep(1)}></button>
                    </span>
                    <StarsRoundedIcon />
                  </div>
                  <div class="card-profile-data-item-description">
                    <div class="card-profile-data-item-description-wrap">
                      <p class="card-profile-data-item-description-number">
                        {totalPointsMonthTrivias}
                      </p>
                      <p class="card-profile-data-item-description-title">
                        PUNTOS DEL MES
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-profile-data-item onboarding-step-2">
                  <div class="card-profile-data-item-icon">
                    <span className="onboarding-button onboarding-button-disabled" id="onboarding-button-2">
                      <button className="button-shepherd" onClick={() => showStep(2)}></button>
                    </span>
                    <StarsRoundedIcon />
                  </div>
                  <div class="card-profile-data-item-description">
                    <div class="card-profile-data-item-description-wrap">
                      <p class="card-profile-data-item-description-number">
                        {totalPointsTrivias}
                      </p>
                      <p class="card-profile-data-item-description-title">
                        ACUMULADO ANUAL
                      </p>
                    </div>
                  </div>
                </div>
                {
                  pointTeam !== null  &&  
                  (
                    <div class="card-profile-data-item onboarding-step-3">
                      <div class="card-profile-data-item-icon">
                        <span className="onboarding-button onboarding-button-disabled" id="onboarding-button-3">
                          <button className="button-shepherd" onClick={() => showStep(3)}></button>
                        </span>
                        <MonetizationOnIcon />
                      </div>
                      <div class="card-profile-data-item-description">
                        <div class="card-profile-data-item-description-wrap">
                          <p class="card-profile-data-item-description-number">
                            {pointTeam}
                          </p>
                          <p class="card-profile-data-item-description-title">
                            PUNTOS
                            MI EQUIPO
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className={`card-profile-data-icons-bottom-pos-${pointTeam !== null ? 2 : 3}`}>
                <div class="card-profile-data-item onboarding-step-4">
                  <div class="card-profile-data-item-icon">
                    <span className="onboarding-button onboarding-button-disabled" id="onboarding-button-4">
                      <button className="button-shepherd" onClick={() => showStep(4)}></button>
                    </span>
                    <img src={trivia_img} />
                  </div>
                  <div class="card-profile-data-item-description">
                    <div class="card-profile-data-item-description-wrap">
                      <p class="card-profile-data-item-description-number">
                        {totalTrivias}
                      </p>
                      <p class="card-profile-data-item-description-title">
                        CANT. TRIVIAS
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card-profile-data-item onboarding-step-5">
                  <div class="card-profile-data-item-icon">
                    <span className="onboarding-button onboarding-button-disabled" id="onboarding-button-5">
                      <button className="button-shepherd" onClick={() => showStep(5)}></button>
                    </span>
                    <img src={mission_img} />
                  </div>
                  <div class="card-profile-data-item-description">
                    <div class="card-profile-data-item-description-wrap">
                      <p class="card-profile-data-item-description-number">
                        {totalCompletedMissions}
                      </p>
                      <p class="card-profile-data-item-description-title">
                        CANT. MISIONES
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </div>
          <div className="activities-div">ACTIVIDADES</div>
          <div className="activities-div-container">
            {quizzes === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              quizzes.map((data, index) => {
                let regex = /T(.)*/;
                let date = '';
                if(data.dateTo == undefined) {
                  date = 'asd';
                } else {
                  date = replaceAll(data.dateTo, "-", "/").replace(regex, "");
                }
                return (
                  <QuizCart
                    key={index}
                    isReaded={data.isReaded}
                    badge={data.badge}
                    title={data.triviaName}
                    description={data.triviaDescription}
                    btnText={
                      !data.userScoreId
                        ? t("play.label", "Play")
                        : t("summary.label", "Summary")
                    }
                    secondary={
                      !data.userScoreId /*|| (new Date(date)<=new Date())*/
                    }
                    id={data.id}
                    time={parseInt(data.timeInSec)}
                    points={data.minApprovalPoints}
                    date={date}
                    data={data}
                    score={data.userScoreId}
                  />
                );
              })
            )}
          </div>
          {quizzes !== null ? quizzes.length === 0 && <div className="activities-div-container"><div className="card-model card-activity" style={{justifyContent: 'center'}}><h3>Actualmente, no tienes actividades para hacer</h3></div></div> : ""}
      </Grid>
        <Grid xs={12} md={3} className="metrics-container">
          {sesion !== null && sesion.company.isShowMetricDashboard && (
          <div class="card-model2 metricas-card">
            <p>MÉTRICAS</p>
            {metrics === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <>
                {metrics && metrics.length === 0 ? (
                  <span>No tienes Métricas</span>
                ) : (
                  <>
                    {metrics.map((data) => (
                      <div class="metrica-item">
                        <div class="metrica-item-icon">
                          <StarBorderIcon />
                        </div>
                        <div class="metrica-item-title">
                          {data.metricConfName}
                        </div>
                        <div
                          class="metrica-item-points"
                          ref={(el) => {
                            if (el != null) {
                              el.style.setProperty(
                                "color",
                                data.color,
                                "important"
                              );
                            }
                          }}
                        >
                          {data.value}
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          )}
          
          {sesion !== null && sesion.company.isShowRankingDashboard && (
            <div class="card-model2 metricas-card">
            <p>
              {sesion.user.position == 3?  'MI EQUIPO': 'MIS COLABORADORES'}
            </p>
            <span class="ranking-sub-titulo">Actividades realizadas</span>
            <p>
              <span class="ranking-sub-titulo">Puntos Anuales</span>
            </p>
            {pointRanking === null ? (
              <span className="spinner-container">
                <SpinnerCircular />
              </span>
            ) : (
              <div className="card-model2">
                <div
                  className="ranking-list-content custom-height-metrics"
                  ref={pointRanking}
                >
                  {pointRanking.map((data, index) => {
                    {/*if(data.user.id == sesion.user.id && (sesion.user.position == 2 || sesion.user.position == 1 
                      || sesion.user.position == 4 || sesion.user.position == 5 || sesion.user.position == 6)) {
                      return <></>;
                    }*/}
                    return (
                      <UserMainRanking
                        collaborators={(sesion.user.position != 3) ? data.user.collaborators : []}
                        position={data.position}
                        name={data.user.name}
                        lastName={data.user.lastName}
                        username={data.user.username}
                        qtyColaborators={data.qtyColaborators}
                        points={data.pointBalance}
                        positionIndex={index}
                        current={sesion.user.id === data.user.id}
                        image={`${environment.motivarnosBackend}${data.user.image}`}
                        id={data.user.id}
                        key={data.user.id}
                        insigniaIcon={false}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>)}
        </Grid>
      
    </div>
  );
};

export default Quizzes;